
  import { Component, Vue } from 'vue-property-decorator';
  import { CREATE_BOOKMAKER_PROMOTION, RESET_STATE } from '@/store/bookmakerPromotions';
  import BookmakerPromotionEdit from '@/components/BookmakerPromotionEdit.vue';

  @Component({
    components: { BookmakerPromotionEdit }
  })
  export default class BookmakersCreate extends Vue {
    created() {
      this.$store.commit(RESET_STATE);
    }

    public save() {
      (this.$refs.bookmakerPromotionEdit as any).$v.$touch();

      if ((this.$refs.bookmakerPromotionEdit as any).$v.$error) {
        return;
      }

      this.$store.dispatch(CREATE_BOOKMAKER_PROMOTION)
        .then(() => this.$router.push({ name: 'app.bookmakerPromotions.list' }));
    }
  }
